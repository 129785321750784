<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-subheader class="px-0"> Posts </v-subheader>
      </v-col>
      <v-col v-for="code in parsedCodes" :key="code.id" cols="6" sm="4" md="3">
        <game :value="code" dense static show-action />
      </v-col>
    </v-row>
  </v-container>
  <!-- <div>
    <h1>Vuex Test with Composition API</h1>
    {{ parsedCodes }}
    <button @click="increase">increase</button>
  </div> -->
</template>

<script>
// Utilities
import { mapGetters } from "vuex";

export default {
  name: "LibraryGames",

  components: {
    Game: () => import("@/components/CodeItem"),
  },

  computed: {
    ...mapGetters("codes", ["parsedCodes"]),
  },
};
</script>

<style scoped>
.v-sheet {
  cursor: pointer;
}
</style>
